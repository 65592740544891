<template>
  <div class="warningInfo">
    <Breadcrumb :title="$route.meta.title" />
    <div class="searchWrap">
      <!-- <div class="typeNewsSelect">
        <label>查詢來源：</label>
        <el-select v-model="newsSelectValue" @change="changeTypeNews" placeholder="請選擇新聞種類" no-data-text="目前無名單">
          <el-option v-for="item in newsTypeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div> -->
      <transition name="fade">
        <section v-if="newsSelectValue==='searchNews'" class="searchNews">
          <div class="itemWrap">
            <label>新聞日期：</label>
            <el-date-picker type="daterange" :editable="false" value-format="yyyy-MM-dd" range-separator="至"
              start-placeholder="開始日期" 
              end-placeholder="結束日期"
              v-model="rangeDate"
            ></el-date-picker>
          </div>
          <!-- <div class="itemWrap">
            <label>情緒指標：</label>
            <el-select v-model="listQuery.Sentiment" placeholder="請選擇情緒指標" no-data-text="目前無名單" multiple clearable>
              <el-option v-for="item in sentimentOption" :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div> -->
          <div class="itemWrap">
            <label>新聞網站：</label>
            <el-select v-model="listQuery.Site" placeholder="請選擇新聞網站" no-data-text="目前無名單" multiple clearable>
              <el-option v-for="(item,index) in webSiteOptions" :key="index"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </div>
          <div class="itemWrap">
            <label>新聞關鍵字：</label>
            <el-input v-model.trim="listQuery.SearchContent" placeholder="請輸入新聞關鍵字"></el-input>
          </div>
        </section>
      </transition>

      <transition name="fade">
        <section v-if="newsSelectValue==='searChsan'" class="searChsan">
          <div class="itemWrap">
            <label>發布日期：</label>
            <el-date-picker type="daterange" :editable="false" value-format="yyyy-MM-dd" range-separator="至"
              start-placeholder="開始日期" 
              end-placeholder="結束日期"
              v-model="rangeDate"
            ></el-date-picker>
          </div>
          <div class="itemWrap">
            <label>查詢方式：</label>
            <el-select v-model="listQuery.Type" placeholder="請選種類" no-data-text="目前無名單" multiple clearable>
              <el-option v-for="item in typeOptions" :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div class="itemWrap">
            <label>名單種類：</label>
            <el-select v-model="searChsanSearchType" @change="changeDataIdOrName" placeholder="請選選擇查詢方式" no-data-text="目前無名單" clearable>
              <el-option v-for="item in listOptions" :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div v-show="!!searChsanSearchType" class="itemWrap">
            <label>關鍵字：</label>
            <el-input v-show="searChsanSearchType==='ListID'"
              v-model.trim="searChsanInputListID" placeholder="請輸入名單編號"
            ></el-input>
            <el-input v-show="searChsanSearchType==='Name'"
              v-model.trim="listQuery.SearchContent" placeholder="請輸入名單名稱"
            ></el-input>
          </div>
        </section>
      </transition>

      <transition name="fade">
        <section class="other" v-if="
          newsSelectValue==='searchConstr'||
          newsSelectValue==='searchLabor'||
          newsSelectValue==='searchEnviroment'
        ">
          <div class="itemWrap">
            <label>違反處分時間：</label>
            <el-date-picker :editable="false" type="daterange" value-format="yyyy-MM-dd" range-separator="至" 
              start-placeholder="開始日期" 
              end-placeholder="結束日期"
              v-model="rangeDate"
            ></el-date-picker>
          </div>
          <div class="itemWrap">
            <label>關鍵字：</label>
            <el-input v-model.trim="listQuery.SearchContent" :placeholder="placeholder"></el-input>
          </div>
        </section>
      </transition>

      <div class="btnWrap"> 
        <el-button type="primary" @click="handleSearch">查詢</el-button>
        <div class="addButton" v-if="isOperator">
          <el-button type="primary" @click="toggleDialog">
            <font-awesome-icon icon="plus" style="margin-right: 5px"/>建立警示名單
          </el-button>
          <el-button type="primary" @click="editRelatedNews">
            <font-awesome-icon icon="plus" style="margin-right: 5px"/>加入關聯資訊
          </el-button>
        </div>
      </div>

    </div>
    
    <div class="listWrap__content">
      <!-- *負面新聞資料* -->
      <el-table v-if="typeNews == 'searchNews'" style="width: 100%" ref="multipleTable"
        :data="tableData"
        @select="getSelectedRow"
      >
        <el-table-column type="selection" width="60" v-if="isOperator"></el-table-column>
        <el-table-column prop="NewsTitle" label="新聞標題" width="400">
          <template slot-scope="scope">
            <a class="goDetailPage" @click="previewDetailPage(scope.row,'detailNews')">{{
              scope.row.NewsTitle
            }}</a>
          </template></el-table-column
        >
        <el-table-column prop="NewsTime" label="新聞時間" width="300">
          <template slot-scope="scope">
            <div>{{ filterDate(scope.row.NewsTime) }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="NewsSite" label="新聞網站" width="150"></el-table-column>
        <el-table-column prop="NewsChannel" label="新聞頻道" width="150"> </el-table-column>
        <el-table-column prop="Sentiment" label="情緒指標">
          <template slot-scope="scope">
            {{ transSentimentName(scope.row.Sentiment) }}
          </template>
        </el-table-column>
      </el-table>
      <!-- *聯合國制裁名單* -->
      <el-table v-if="typeNews == 'searChsan'" style="width: 100%" ref="multipleTable"
        :data="tableData"
        @select="getSelectedRow"
      >
        <el-table-column type="selection" width="60" v-if="isOperator" fixed></el-table-column>
        <el-table-column prop="ListID" label="名單編號" width="150">
          <template slot-scope="scope">
            <a class="goDetailPage" @click="previewDetailPage(scope.row,'tableDetailNews','searChsan')">
              {{scope.row.ListID}}
            </a>
          </template>
        </el-table-column>
        <el-table-column label="名單名稱" prop="combination" width="300"></el-table-column>
        <el-table-column prop="SourceName" label="來源名稱" width="300"></el-table-column>
        <el-table-column prop="ListType" label="名單分類" width="150"></el-table-column>
        <el-table-column prop="UNListType" label="聯合國名單種類" width="150"></el-table-column>
        <el-table-column prop="PublicDt" label="發布時間"></el-table-column>
      </el-table>
      <!-- *政府拒絕往來廠商名單* -->
      <el-table v-if="typeNews == 'searchConstr'" style="width: 100%" ref="multipleTable" max-height="600"
        :data="tableData"
        @select="getSelectedRow"
      >
        <el-table-column type="selection" width="60" fixed v-if="isOperator"></el-table-column>
        <el-table-column prop="CaseName" label="標案名稱" width="150">
            <template slot-scope="scope">
              <a class="goDetailPage" @click="previewDetailPage(scope.row,'tableDetailNews','searchConstr')">{{scope.row.CaseName}}</a>
            </template>
        </el-table-column>
        <el-table-column prop="UniComNm" label="廠商代碼" width="100"></el-table-column>
        <el-table-column prop="ComName" label="廠商名稱" width="150"></el-table-column>
        <el-table-column prop="GPActStatus" label="符合政府採購法第101條所列之情形" width="400"></el-table-column>
        <el-table-column prop="PublicDt" label="公告日期" width="120"></el-table-column>
        <el-table-column prop="EffectDt" label="拒絕往來生效日"></el-table-column>
      </el-table>
      <!-- *違反勞動法名單* -->
      <el-table v-if="typeNews == 'searchLabor'"  style="width: 100%" ref="multipleTable"
        :data="tableData"
        @select="getSelectedRow"
      >
        <el-table-column type="selection" width="60" fixed v-if="isOperator"></el-table-column>
        <el-table-column prop="UnitEntity" label="事業單位名稱" width="180">
          <template slot-scope="scope">
            <a class="goDetailPage" @click="previewDetailPage(scope.row,'tableDetailNews','searchLabor')">{{scope.row.UnitEntity}}</a>
          </template>
        </el-table-column>
        <el-table-column prop="PublicDt" label="公告日期" width="150"></el-table-column>
        <el-table-column prop="PunishDt" label="處分日期" width="150"></el-table-column>
        <el-table-column prop="VioLaw" label="違法法規法條"></el-table-column>
      </el-table>
      <!-- *環保署裁罰處分名單* -->
      <el-table v-if="typeNews == 'searchEnviroment'" style="width:100%" ref="multipleTable" max-height="600"
        :data="tableData"
        @select="getSelectedRow"
      >
        <el-table-column type="selection" width="60" fixed v-if="isOperator"></el-table-column>
        <el-table-column prop="CaseName" label="案件名稱" width="250">
          <template slot-scope="scope">
            <a class="goDetailPage" @click="previewDetailPage(scope.row,'tableDetailNews','searchEnviroment')">{{scope.row.CaseName}}</a>
          </template>
        </el-table-column>
        <el-table-column prop="UnitName" label="行為人名稱" width="120"></el-table-column>
        <el-table-column prop="PunishTime" label="違反處分時間" width="120"></el-table-column>
        <el-table-column prop="VioLaw" label="違反法令"></el-table-column>
      </el-table>
    </div>

    <div v-if="openPopUp" class="WarningListPopUp">
      <div class="popup">
        <i class="el-icon-close" @click="toggleDialog"></i>
        <p>請選擇要新增的名單類別</p>
        <div>
          <el-button type="danger" @click="addWarningForm('people','add')">人名</el-button>
          <el-button type="danger" @click="addWarningForm('org','add')">組織</el-button>
        </div>
      </div>
    </div>
    <Pagination
      @currentChange="pageChange"
      :pageSize="this.listQuery.Page_Size"
      :propsCurrentPage="this.listQuery.Page_Num"
      :totalLists="totalLists"
    ></Pagination>
  </div>
</template>

<script>
import Pagination from '../components/Pagination.vue';
import Breadcrumb from '../../src/components/Breadcrumb.vue';
const requestData = {
  //共用
  Start_Date: '',
  End_Date: '',
  SearchContent:"",//關鍵字
  NewsID: '',
  Page_Size: 10, //每頁有幾筆
  Page_Num: 1, //目前頁數
  //負面新聞
  Site:[],//新聞網站
  Sentiment:[],//情緒指標
  //聯合國
  ListID:[],
  Type: '',
  //其餘新聞種類
}
export default {
  components: {Breadcrumb,Pagination},
  data() {
    return {
      placeholder:"",
      typeNews: 'searchNews',
      newsTypeOptions: [
        {
          value: 'searchNews',
          label: '負面新聞資料',
        },
        {
          value: 'searChsan',
          label: '聯合國制裁名單',
        },
        {
          value: 'searchConstr',
          label: '政府拒絕往來廠商名單',
        },
        {
          value: 'searchLabor',
          label: '違反勞動法名單',
        },
        {
          value: 'searchEnviroment',
          label: '環保署裁罰處分名單',
        },
      ],
      sentimentOption: [
        {
          value: 'positive',
          label: '正向',
        },
        {
          value: 'neutral',
          label: '中立',
        },
        {
          value: 'negative',
          label: '負向',
        },
      ],
      typeOptions: [
        {
          value: 'INDIVIDUAL',
          label: '人員',
        },
        {
          value: 'ENTITY',
          label: '組織',
        },
      ],
      listOptions: [
        {
          value: 'ListID',
          label: '名單編號',
        },
        {
          value: 'Name',
          label: '名單名稱',
        },
      ],
      totalLists: null,
      openPopUp:false,
      searChsanInputListID:"",
      searChsanSearchType:"",
      webSiteOptions: [],
      newsSelectValue:"searchNews",
      rangeDate:[],
      listQuery:JSON.parse(JSON.stringify(requestData)),
      tableData: [],
      userRole: this.$store.state.userInfo.Role,
      multipleSelection:[],
      manageAllSelection:{
        searchNews:[],
        searChsan:[],
        searchConstr:[],
        searchLabor:[],
        searchEnviroment:[]
      },
    };
  },
  computed: {
    composeName(row){
      console.log(row);
    },
    filterDate() {
      return (date) => {
        return this.$dayjs(date).format('YYYY-MM-DD HH:mm:ss');
      };
    },
    transSentimentName(){
      return (En) => {
        let transName = "無";
        if(En){
          switch(En){
            case 'positive':
              transName = '正向'
              break
            case 'negative':
              transName = '負向'
              break
            case 'neutral':
              transName = '中立'
              break
          }
        }
        return transName;
      };
    },
    isOperator(){
      let result = null;
      if(this.userRole==='operator'){
        result = true
      }else{
         result = false
      }
      return result
    },
  },
  methods: {
    addWarningForm(formType,actionsType) {
      this.$router.push({
        name: "warningListAdd",
        query: {
          caseStatus:actionsType,
          warningFormType: formType,//people或 org
          typeNews: this.typeNews,
          DataID:JSON.stringify(this.manageAllSelection),
        }
      });
    },
    editRelatedNews() {
      this.$router.push({
        name: "relatedNews",
        query: {
          caseStatus:'',
          warningFormType: 'people',//people或 org
          typeNews: this.typeNews,
          DataID:JSON.stringify(this.manageAllSelection),
        }
      });
    },
    toggleDialog() {
      this.openPopUp = !this.openPopUp;
    },
    changeDataIdOrName(){
      if(this.searChsanSearchType==='ListID'){
        this.listQuery.ListID = [];
        delete this.listQuery.SearchContent;
      }else if(this.searChsanSearchType==='Name'){
        delete this.listQuery.ListID;
        this.searChsanInputListID = ""
      }else{
        delete this.listQuery.ListID;
        delete this.listQuery.SearchContent;
        this.searChsanInputListID = ""
      }
    },
    isUserDelete(){
      let keys = Object.keys(this.listQuery);
      keys.forEach((key)=>{
        if(key==='IsUser'){
          delete this.listQuery[key]
        }
      })
    },
    changeTypeNews(){
      if(this.typeNews!==this.newsSelectValue){
        this.typeNews = this.newsSelectValue  
        this.listQuery = JSON.parse(JSON.stringify(requestData))
        this.rangeDate = [];
      }
      if(this.newsSelectValue==='searchConstr'){
        this.placeholder = '請輸入廠商名稱'
      }else if(this.newsSelectValue==='searchLabor'){
        this.placeholder = '請輸入單位名稱'
      }else if(this.newsSelectValue==='searchEnviroment'){
        this.placeholder = '請輸入行為人名稱'
      }
      this.isUserDelete()
      this.getList()
    },
    handleSearch(){
      this.listQuery.Page_Num = 1
      this.listQuery.IsUser = true
      this.getList()
    },
    getWebSiteOptions() {
      return new Promise((resolve)=>{
        let temp = {
          Type: ['NewsSite'],
        };
        this.$api.system.getOptions(temp).then((res) => {
          this.webSiteOptions = res.NewsSite;
          resolve()
        });
      })
    },
    deleteNullValue() {
      if(!!this.rangeDate){
        if(this.rangeDate.length !== 0){
          this.listQuery.Start_Date = this.rangeDate[0]
          this.listQuery.End_Date = this.rangeDate[1]
        }
      }else{
        this.listQuery.Start_Date = "";
        this.listQuery.End_Date = "";
      }
      let keys = Object.keys(this.listQuery)
      keys.forEach((key)=>{
        if(Array.isArray(this.listQuery[key]) && this.listQuery[key].length===0){
          delete this.listQuery[key]
        }
        if(!this.listQuery[key]){
          delete this.listQuery[key]
        }
      })
    },
    getList() {
      this.$store.dispatch('loadingHandler', true);
      if(this.typeNews==='searChsan' && this.searChsanInputListID){
        this.listQuery.ListID = [];
        this.listQuery.ListID.push(this.searChsanInputListID)
      }
      this.deleteNullValue();
      this.$api.data[this.typeNews](this.listQuery).then((res) => {
        this.tableData = [];
        this.totalLists = res.total;
        if(this.typeNews==='searChsan'){
          this.tableData = res.data.map((item)=>item.SanList)
          this.tableData.forEach((item,index)=>{
            // this.tableData[index].push({})
            item.combination = `${item.FirstName}${" "}${item.SecondName}${" "}${item.ThirdName}`
          })

        }else if(this.typeNews==='searchNews'){
          let order = 1;
          res.data.forEach((item) => {
            item.order = order;
            order++;
          });
          this.tableData = res.data;
        }
        else{
          this.tableData = res.data;
        }
        this.$store.dispatch('loadingHandler', false);
        this.makeTableShowChecked()
      });
    },
    makeTableShowChecked(){
      this.$nextTick(()=>{
        this.tableData.forEach((item) => {
          this.manageAllSelection[this.typeNews].forEach((selectedItem) => {
            if (item.DataID === selectedItem.DataID) {
              this.$refs.multipleTable.toggleRowSelection(item, true);
            }
          });
        });
      })
    },
    /*手動勾選數據Checkbox所觸發的event*/
    getSelectedRow(selection,row){
      let manageAllDataID = this.manageAllSelection[this.typeNews].map((item)=>item.DataID);
      let isIncludes = manageAllDataID.includes(row.DataID);
      if(isIncludes){
        // delete
        let index = manageAllDataID.indexOf(row.DataID)
        this.manageAllSelection[this.typeNews].splice(index,1)
      }else{
        //push
        this.manageAllSelection[this.typeNews].push(row)
      }
    },
    previewDetailPage(item,typeDetail,typeNews){
      const route = this.$router.resolve({
        name:typeDetail,
        query: {
          DataID:item.DataID,
          typeNews:typeNews
        }
      })
      window.open(route.href,'_blank')
    },
    pageChange(val) {
      this.listQuery.Page_Num = val;
      this.isUserDelete();
      this.getList(this.typeNews);
    }, 
  },
  async mounted() {
    await this.getWebSiteOptions();
    await this.getList()
  },
};
</script>

<style lang="scss" scoped>
.warningInfo {
  background: linear-gradient(-135deg, #00abb9 20%, #fff 50%, transparent 50%)
    center center / 100% 100%;
  min-height: 100vh;
  padding: 0px 20px 0px 20px;
  .searchWrap {
    .btnWrap{
      display: flex;
      justify-content: space-between;
      margin: 10px 0px;
    }
    .typeNewsSelect{
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      label{
        width: 100px;
        display: block;
        text-align: right;
      }
    }
    .searchNews{
      .itemWrap{
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        label{
          width: 100px;
          display: block;
          text-align: right;
        }
        &:last-child{
          display: flex;
          align-items: center;
          .el-input{
            width: 300px;
          }
        }
      }
    }
    .searChsan{
      .itemWrap{
        display: flex;
        align-items: center;
        margin-top:10px;
        margin-bottom: 10px;
        label{
          width: 100px;
          display: block;
          text-align: right;
        }
        &:last-child{
          display: flex;
          align-items: center;
          .el-input{
            width: 300px;
          }
        }
      }
    }
    .other{
      .itemWrap{
        display: flex;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;
        label{
          width: 120px;
          display: block;
          text-align: right;
        }
        &:last-child{
          display: flex;
          align-items: center;
          .el-input{
            width: 300px;
          }
        }
      }
    }
  }
  .WarningListPopUp {
    width: 100%;
    height: 100vh;
    background-color: rgba(133, 133, 133, 0.7);
    position: fixed;
    z-index: 9999999;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .popup {
      position: relative;
      text-align: center;
      width: 20%;
      padding: 5%;
      background-color: white;
      border-radius: 10px;
      .el-icon-close{
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  a{
    text-decoration: underline;
    color: blue;
    cursor: pointer;
    
  }
}
</style>
