var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "warningInfo" },
    [
      _c("Breadcrumb", { attrs: { title: _vm.$route.meta.title } }),
      _c(
        "div",
        { staticClass: "searchWrap" },
        [
          _c("transition", { attrs: { name: "fade" } }, [
            _vm.newsSelectValue === "searchNews"
              ? _c("section", { staticClass: "searchNews" }, [
                  _c(
                    "div",
                    { staticClass: "itemWrap" },
                    [
                      _c("label", [_vm._v("新聞日期：")]),
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          editable: false,
                          "value-format": "yyyy-MM-dd",
                          "range-separator": "至",
                          "start-placeholder": "開始日期",
                          "end-placeholder": "結束日期"
                        },
                        model: {
                          value: _vm.rangeDate,
                          callback: function($$v) {
                            _vm.rangeDate = $$v
                          },
                          expression: "rangeDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "itemWrap" },
                    [
                      _c("label", [_vm._v("新聞網站：")]),
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "請選擇新聞網站",
                            "no-data-text": "目前無名單",
                            multiple: "",
                            clearable: ""
                          },
                          model: {
                            value: _vm.listQuery.Site,
                            callback: function($$v) {
                              _vm.$set(_vm.listQuery, "Site", $$v)
                            },
                            expression: "listQuery.Site"
                          }
                        },
                        _vm._l(_vm.webSiteOptions, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item, value: item }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "itemWrap" },
                    [
                      _c("label", [_vm._v("新聞關鍵字：")]),
                      _c("el-input", {
                        attrs: { placeholder: "請輸入新聞關鍵字" },
                        model: {
                          value: _vm.listQuery.SearchContent,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.listQuery,
                              "SearchContent",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "listQuery.SearchContent"
                        }
                      })
                    ],
                    1
                  )
                ])
              : _vm._e()
          ]),
          _c("transition", { attrs: { name: "fade" } }, [
            _vm.newsSelectValue === "searChsan"
              ? _c("section", { staticClass: "searChsan" }, [
                  _c(
                    "div",
                    { staticClass: "itemWrap" },
                    [
                      _c("label", [_vm._v("發布日期：")]),
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          editable: false,
                          "value-format": "yyyy-MM-dd",
                          "range-separator": "至",
                          "start-placeholder": "開始日期",
                          "end-placeholder": "結束日期"
                        },
                        model: {
                          value: _vm.rangeDate,
                          callback: function($$v) {
                            _vm.rangeDate = $$v
                          },
                          expression: "rangeDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "itemWrap" },
                    [
                      _c("label", [_vm._v("查詢方式：")]),
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "請選種類",
                            "no-data-text": "目前無名單",
                            multiple: "",
                            clearable: ""
                          },
                          model: {
                            value: _vm.listQuery.Type,
                            callback: function($$v) {
                              _vm.$set(_vm.listQuery, "Type", $$v)
                            },
                            expression: "listQuery.Type"
                          }
                        },
                        _vm._l(_vm.typeOptions, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "itemWrap" },
                    [
                      _c("label", [_vm._v("名單種類：")]),
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "請選選擇查詢方式",
                            "no-data-text": "目前無名單",
                            clearable: ""
                          },
                          on: { change: _vm.changeDataIdOrName },
                          model: {
                            value: _vm.searChsanSearchType,
                            callback: function($$v) {
                              _vm.searChsanSearchType = $$v
                            },
                            expression: "searChsanSearchType"
                          }
                        },
                        _vm._l(_vm.listOptions, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !!_vm.searChsanSearchType,
                          expression: "!!searChsanSearchType"
                        }
                      ],
                      staticClass: "itemWrap"
                    },
                    [
                      _c("label", [_vm._v("關鍵字：")]),
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.searChsanSearchType === "ListID",
                            expression: "searChsanSearchType==='ListID'"
                          }
                        ],
                        attrs: { placeholder: "請輸入名單編號" },
                        model: {
                          value: _vm.searChsanInputListID,
                          callback: function($$v) {
                            _vm.searChsanInputListID =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "searChsanInputListID"
                        }
                      }),
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.searChsanSearchType === "Name",
                            expression: "searChsanSearchType==='Name'"
                          }
                        ],
                        attrs: { placeholder: "請輸入名單名稱" },
                        model: {
                          value: _vm.listQuery.SearchContent,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.listQuery,
                              "SearchContent",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "listQuery.SearchContent"
                        }
                      })
                    ],
                    1
                  )
                ])
              : _vm._e()
          ]),
          _c("transition", { attrs: { name: "fade" } }, [
            _vm.newsSelectValue === "searchConstr" ||
            _vm.newsSelectValue === "searchLabor" ||
            _vm.newsSelectValue === "searchEnviroment"
              ? _c("section", { staticClass: "other" }, [
                  _c(
                    "div",
                    { staticClass: "itemWrap" },
                    [
                      _c("label", [_vm._v("違反處分時間：")]),
                      _c("el-date-picker", {
                        attrs: {
                          editable: false,
                          type: "daterange",
                          "value-format": "yyyy-MM-dd",
                          "range-separator": "至",
                          "start-placeholder": "開始日期",
                          "end-placeholder": "結束日期"
                        },
                        model: {
                          value: _vm.rangeDate,
                          callback: function($$v) {
                            _vm.rangeDate = $$v
                          },
                          expression: "rangeDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "itemWrap" },
                    [
                      _c("label", [_vm._v("關鍵字：")]),
                      _c("el-input", {
                        attrs: { placeholder: _vm.placeholder },
                        model: {
                          value: _vm.listQuery.SearchContent,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.listQuery,
                              "SearchContent",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "listQuery.SearchContent"
                        }
                      })
                    ],
                    1
                  )
                ])
              : _vm._e()
          ]),
          _c(
            "div",
            { staticClass: "btnWrap" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSearch } },
                [_vm._v("查詢")]
              ),
              _vm.isOperator
                ? _c(
                    "div",
                    { staticClass: "addButton" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.toggleDialog }
                        },
                        [
                          _c("font-awesome-icon", {
                            staticStyle: { "margin-right": "5px" },
                            attrs: { icon: "plus" }
                          }),
                          _vm._v("建立警示名單 ")
                        ],
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.editRelatedNews }
                        },
                        [
                          _c("font-awesome-icon", {
                            staticStyle: { "margin-right": "5px" },
                            attrs: { icon: "plus" }
                          }),
                          _vm._v("加入關聯資訊 ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "listWrap__content" },
        [
          _vm.typeNews == "searchNews"
            ? _c(
                "el-table",
                {
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.tableData },
                  on: { select: _vm.getSelectedRow }
                },
                [
                  _vm.isOperator
                    ? _c("el-table-column", {
                        attrs: { type: "selection", width: "60" }
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: {
                      prop: "NewsTitle",
                      label: "新聞標題",
                      width: "400"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "a",
                                {
                                  staticClass: "goDetailPage",
                                  on: {
                                    click: function($event) {
                                      return _vm.previewDetailPage(
                                        scope.row,
                                        "detailNews"
                                      )
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(scope.row.NewsTitle))]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1031227391
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "NewsTime",
                      label: "新聞時間",
                      width: "300"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm.filterDate(scope.row.NewsTime))
                                )
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      616771582
                    )
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "NewsSite", label: "新聞網站", width: "150" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "NewsChannel",
                      label: "新聞頻道",
                      width: "150"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "Sentiment", label: "情緒指標" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.transSentimentName(scope.row.Sentiment)
                                  ) +
                                  " "
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1546290017
                    )
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.typeNews == "searChsan"
            ? _c(
                "el-table",
                {
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.tableData },
                  on: { select: _vm.getSelectedRow }
                },
                [
                  _vm.isOperator
                    ? _c("el-table-column", {
                        attrs: { type: "selection", width: "60", fixed: "" }
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: { prop: "ListID", label: "名單編號", width: "150" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "a",
                                {
                                  staticClass: "goDetailPage",
                                  on: {
                                    click: function($event) {
                                      return _vm.previewDetailPage(
                                        scope.row,
                                        "tableDetailNews",
                                        "searChsan"
                                      )
                                    }
                                  }
                                },
                                [_vm._v(" " + _vm._s(scope.row.ListID) + " ")]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3940079327
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "名單名稱",
                      prop: "combination",
                      width: "300"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "SourceName",
                      label: "來源名稱",
                      width: "300"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "ListType", label: "名單分類", width: "150" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "UNListType",
                      label: "聯合國名單種類",
                      width: "150"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "PublicDt", label: "發布時間" }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.typeNews == "searchConstr"
            ? _c(
                "el-table",
                {
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: { "max-height": "600", data: _vm.tableData },
                  on: { select: _vm.getSelectedRow }
                },
                [
                  _vm.isOperator
                    ? _c("el-table-column", {
                        attrs: { type: "selection", width: "60", fixed: "" }
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: {
                      prop: "CaseName",
                      label: "標案名稱",
                      width: "150"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "a",
                                {
                                  staticClass: "goDetailPage",
                                  on: {
                                    click: function($event) {
                                      return _vm.previewDetailPage(
                                        scope.row,
                                        "tableDetailNews",
                                        "searchConstr"
                                      )
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(scope.row.CaseName))]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3023862696
                    )
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "UniComNm", label: "廠商代碼", width: "100" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "ComName", label: "廠商名稱", width: "150" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "GPActStatus",
                      label: "符合政府採購法第101條所列之情形",
                      width: "400"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "PublicDt", label: "公告日期", width: "120" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "EffectDt", label: "拒絕往來生效日" }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.typeNews == "searchLabor"
            ? _c(
                "el-table",
                {
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.tableData },
                  on: { select: _vm.getSelectedRow }
                },
                [
                  _vm.isOperator
                    ? _c("el-table-column", {
                        attrs: { type: "selection", width: "60", fixed: "" }
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: {
                      prop: "UnitEntity",
                      label: "事業單位名稱",
                      width: "180"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "a",
                                {
                                  staticClass: "goDetailPage",
                                  on: {
                                    click: function($event) {
                                      return _vm.previewDetailPage(
                                        scope.row,
                                        "tableDetailNews",
                                        "searchLabor"
                                      )
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(scope.row.UnitEntity))]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      738790851
                    )
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "PublicDt", label: "公告日期", width: "150" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "PunishDt", label: "處分日期", width: "150" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "VioLaw", label: "違法法規法條" }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.typeNews == "searchEnviroment"
            ? _c(
                "el-table",
                {
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: { "max-height": "600", data: _vm.tableData },
                  on: { select: _vm.getSelectedRow }
                },
                [
                  _vm.isOperator
                    ? _c("el-table-column", {
                        attrs: { type: "selection", width: "60", fixed: "" }
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: {
                      prop: "CaseName",
                      label: "案件名稱",
                      width: "250"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "a",
                                {
                                  staticClass: "goDetailPage",
                                  on: {
                                    click: function($event) {
                                      return _vm.previewDetailPage(
                                        scope.row,
                                        "tableDetailNews",
                                        "searchEnviroment"
                                      )
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(scope.row.CaseName))]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3319124548
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "UnitName",
                      label: "行為人名稱",
                      width: "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "PunishTime",
                      label: "違反處分時間",
                      width: "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "VioLaw", label: "違反法令" }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.openPopUp
        ? _c("div", { staticClass: "WarningListPopUp" }, [
            _c("div", { staticClass: "popup" }, [
              _c("i", {
                staticClass: "el-icon-close",
                on: { click: _vm.toggleDialog }
              }),
              _c("p", [_vm._v("請選擇要新增的名單類別")]),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function($event) {
                          return _vm.addWarningForm("people", "add")
                        }
                      }
                    },
                    [_vm._v("人名")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function($event) {
                          return _vm.addWarningForm("org", "add")
                        }
                      }
                    },
                    [_vm._v("組織")]
                  )
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _c("Pagination", {
        attrs: {
          pageSize: this.listQuery.Page_Size,
          propsCurrentPage: this.listQuery.Page_Num,
          totalLists: _vm.totalLists
        },
        on: { currentChange: _vm.pageChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }