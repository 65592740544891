<template>
  <div>
    <el-breadcrumb
      separator-class="el-icon-arrow-right"
      class="projSearch--breadcrumb"
    >
      <el-breadcrumb-item :to="{ name: 'home' }"
        ><font-awesome-icon
          icon="home"
          style="margin-right:10px;"
        />首頁</el-breadcrumb-item
      >
      <el-breadcrumb-item
        ><font-awesome-icon icon="list-ul" style="margin-right:10px;" />{{
          title
        }}</el-breadcrumb-item
      >
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: "None",
    },
  },
};
</script>

<style lang="scss" scoped>
.projSearch {
  width: 100%;
  height: 100vh;
  min-height: 1024px;
  padding: 0px 30px;
  position: relative;
  background: linear-gradient(-135deg, #00abb9 20%, #fff 50%, transparent 50%)
    center center / 100% 100%;

  &--breadcrumb {
    padding: 20px 10px;
  }
}
</style>
