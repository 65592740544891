var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-breadcrumb",
        {
          staticClass: "projSearch--breadcrumb",
          attrs: { "separator-class": "el-icon-arrow-right" }
        },
        [
          _c(
            "el-breadcrumb-item",
            { attrs: { to: { name: "home" } } },
            [
              _c("font-awesome-icon", {
                staticStyle: { "margin-right": "10px" },
                attrs: { icon: "home" }
              }),
              _vm._v("首頁")
            ],
            1
          ),
          _c(
            "el-breadcrumb-item",
            [
              _c("font-awesome-icon", {
                staticStyle: { "margin-right": "10px" },
                attrs: { icon: "list-ul" }
              }),
              _vm._v(_vm._s(_vm.title))
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }